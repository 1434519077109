<div>
    <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Connectez-vous">
            <div class="d-flex flex-column align-content-center">
                <mat-form-field appearance="fill">
                    <mat-label>email ou pseudo</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>mot de passe</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <button mat-raised-button color="primary">se connecter</button>
                <div> ou se connecter via :</div>
                <button mat-raised-button color="primary">facebook</button>
                <button mat-raised-button color="primary">France connect</button>
            </div>
        </mat-tab>
        <mat-tab label="Inscrivez-vous">
            <div class="d-flex flex-column align-content-center">
                <mat-form-field appearance="fill">
                    <mat-label>email ou pseudo</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>mot de passe</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <button mat-raised-button color="primary">créer son compte</button>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
