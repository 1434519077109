<div class="w-100 vote row no-gutters">
	<!--	<div class="votes">-->
	<!--	<div class="votes d-flex flex-row">-->
	<div class="col-md-auto col-12 p-2 vote-question font-size2">Votre opinion du jour ?</div>
	<div class="col-md-auto col-12 flex-grow-1 d-flex flex-row justify-content-center vote-radio-container">
		<!--		<div class="flex-grow-1 d-flex flex-row votes-radio-container">-->
		<mat-radio-group [(ngModel)]="choice" class="vote-radio-group">
			<div>
				<mat-radio-button *ngFor="let opinion of opinions" [value]="opinion"
								  class="vote-radio-button">
					<div [style.border-bottom]="'3px solid'+opinion.color" class="vote-radio-button-label">
						{{opinion.label}}
					</div>
				</mat-radio-button>
			</div>
		</mat-radio-group>
	</div>
	<div class="col-md-auto col-12 vote-validate">
		<button *ngIf="canVote" (click)="onClick('vote')"
				[disabled]="!choice"
				[style.backgroundColor]="choice ? choice.color : ''" aria-label="vote" mat-raised-button>
			envoyer :
			{{choice ? choice.label : ""}}
		</button>
		<div *ngIf="validated"  mat-raised-button>
			<fa-icon (click)="onClick('reset')" [icon]="faVoteYea" [style.color]="choice ? choice.color : ''"
					 class="icon"></fa-icon>
			Merci ! <br>
			prochain vote dans {{nextTime}}
		</div>
	</div>
</div>
