<mat-toolbar color="primary">
  <fa-icon (click)="onClick('collapse')" [icon]="faBars" class="icon"></fa-icon>
  <div class="title" routerLink="">
    Liquide Opinion
  </div>
  <!--  <div class="example-button-row">-->
<!--  <button mat-raised-button>Basic</button>-->
<!--  <button color="primary" mat-raised-button>Primary</button>-->
<!--  <button color="accent" mat-raised-button>Accent</button>-->
<!--  <button color="warn" mat-raised-button>Warn</button>-->
<!--  <button disabled mat-raised-button>Disabled</button>-->
<!--  <button class="fa-gradient" (click)="onClick('like')" aria-label="like" color="primary" mat-icon-button>-->
<!--    <mat-icon color="primary">favorite</mat-icon>-->
<!--  </button>-->
<!--  <button class="fa-gradient" (click)="onClick('share')" aria-label="share" color="warn" mat-icon-button>-->
<!--    <mat-icon color="primary">share</mat-icon>-->
<!--  </button>-->
</mat-toolbar>

