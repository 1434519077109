<div class="candidate w-100">
  <div class="row no-gutters">
    <div class="col-xl-6 col-lg-12 pr-xl-3 overflow-auto">
      <mat-card class="bgWhite candidate-header d-flex flex-row">
        <div class="flex-grow-1 d-flex flex-column">
          <div class="flex-grow-1 candidate-title font-size0">
            {{candidate.title}}
          </div>
          <div class="candidate-share font-size3">
            <button mat-icon-button>
              <mat-icon color="primary">share</mat-icon>
            </button>
            <button mat-icon-button>
              <mat-icon color="primary">facebook</mat-icon>
            </button>
            <button mat-icon-button>
              <mat-icon color="primary">facebook</mat-icon>
            </button>
            <button mat-icon-button>
              <mat-icon color="primary">email</mat-icon>
            </button>
          </div>
        </div>
        <div [style.border-color]="getColorFromScore(candidate.score)"
             class="candidate-score font-size00">
          {{candidate.score}}
        </div>
      </mat-card>
      <mat-card class="bgWhite candidate-image">
        <img [src]=candidate.images alt="">
      </mat-card>
      <mat-card class="bgWhite w-100">
        <div class="candidate-descr font-size2">
          {{candidate.longDescription}}</div>
      </mat-card>
    </div>
    <div class="col-xl-6 col-lg-12 overflow-auto">
      <mat-card class="bgWhite">
        <app-chart-candidate></app-chart-candidate>
      </mat-card>
      <mat-card class="bgWhite">
        <app-vote [opinions]="candidate.opinions" [candidateId]="candidate.id"></app-vote>
      </mat-card>
      <mat-card class="bgWhite">
        <div class="comments">
          <disqus (newComment)="onComment($event)" (paginate)="onPaginate($event)" (ready)="onReady($event)"
                  [category]="catId"
                  [identifier]="pageId" [language]="'fr'" [url]="url"></disqus>
        </div>
      </mat-card>
    </div>
  </div>
</div>
