<div class="chart">
  <!--          width="416" height="197"-->
  <canvas baseChart #candidateChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [colors]="barChartColors"
          [legend]="barChartLegend"
          [chartType]="barChartType">
    <!--          (chartHover)="chartHovered($event)"-->
    <!--          (chartClick)="chartClicked($event)">-->
    <!--          [plugins]="barChartPlugins"-->
  </canvas>
</div>
