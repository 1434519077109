<app-progress-ring></app-progress-ring>
<div class="main d-flex flex-column">
  <app-top-bar (btnClick)="snav.toggle()" class="top-bar"></app-top-bar>
  <mat-sidenav-container class="content">
    <mat-sidenav #snav [fixedInViewport]="!mobileQuery.matches" [opened]="!mobileQuery.matches"
                 [mode]="mobileQuery.matches ? 'over' : 'side'" fixedTopGap="64">
      <div class="sideBar bgWhite">
        <app-side-bar></app-side-bar>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="dashboard">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
