<div class="sideMenu d-flex flex-column">
  <div *ngIf="userConnected" class="sections header d-flex flex-row">
    <div class="header-photo mr-2">
      <img [src]=user.photo alt="">
    </div>
    <div class="profil flex-grow-1">
      <div class="profil-name font-size2 text-capitalize font-weight-bold"
           title="{{user.username}}">{{user.username}}</div>
      <div class="profil-email font-size3" title="{{user.email}}">{{user.email}}</div>
    </div>
    <button (click)="onClick('logout')" aria-label="logout" mat-icon-button>
      <mat-icon color="primary">logout</mat-icon>
    </button>
    <button (click)="onClick('settings')" aria-label="settings" mat-icon-button>
      <mat-icon color="primary">settings</mat-icon>
    </button>
  </div>
  <div *ngIf="!userConnected" class="sections header firstCap">
    <button mat-raised-button (click)="login()" color="primary">connectez vous</button>
  </div>
  <div class="sections">
    <button (click)="createSurvey()" class="w-100 d-flex flex-row radius" color="primary" mat-raised-button>
      <div class="font-size2 firstCap"><fa-icon [icon]="faPlus" class="icon mr-1"></fa-icon> créer un sondage</div>
    </button>
  </div>
  <div class="sections">

    <!-- sepcial event -->
    <div class="item d-flex flex-row" routerLink="event" routerLinkActive="itemSelected">
      <fa-icon [icon]="faHorn" class="icon" size="lg"></fa-icon>
      <div class="flex-grow-1 ml-1 font-size2 firstCap">Spécial évenements</div>
      <div class="number">10</div>
    </div>

    <!-- mes sondages -->
    <div class="item d-flex flex-row" routerLink="favorites" routerLinkActive="itemSelected">
      <fa-icon [icon]="faHeart" class="icon" size="lg"></fa-icon>
      <div class="flex-grow-1 ml-1 font-size2 firstCap">Mes sondages préférés</div>
      <div class="number">10</div>
    </div>

    <!-- mes sondages créés -->
    <div class="item d-flex flex-row" routerLink="created" routerLinkActive="itemSelected">
      <fa-icon [icon]="faPoll" class="icon" size="lg"></fa-icon>
      <div class="flex-grow-1 ml-1 font-size2 firstCap">Mes sondages créés</div>
      <div class="number">10</div>
    </div>


    <!-- me concernant -->
    <div class="item d-flex flex-row" routerLink="participate" routerLinkActive="itemSelected">
      <fa-icon [icon]="faVoteYea" class="icon" size="lg"></fa-icon>
      <div class="flex-grow-1 ml-1 font-size2 firstCap">participés</div>
      <div class="number">10</div>
    </div>

    <!-- me concernant -->
    <div class="item d-flex flex-row" routerLink="shared" routerLinkActive="itemSelected">
      <fa-icon [icon]="faShare" class="icon" size="lg"></fa-icon>
      <div class="flex-grow-1 ml-1 font-size2 firstCap">partagés avec moi </div>
      <div class="number">10</div>
    </div>

    <!-- sondage public -->
    <div class="item d-flex flex-row" routerLink="official" routerLinkActive="itemSelected">
      <fa-icon [icon]="faAward" class="icon" size="lg"></fa-icon>
      <div class="flex-grow-1 ml-1 font-size2 firstCap">sondage officiel</div>
      <div class="">10</div>
    </div>
    <!-- sondage public -->
    <div class="item d-flex flex-row" routerLink="public" routerLinkActive="itemSelected">
      <fa-icon [icon]="faeuro" class="icon" size="lg"></fa-icon>
      <div class="flex-grow-1 ml-1 font-size2 firstCap">sondage public</div>
      <div class="">10</div>
    </div>
  </div>

  <mat-divider class="m-1"></mat-divider>

  <div class="sections">
    <div class="item d-flex flex-row" routerLink="expired" routerLinkActive="itemSelected">
      <fa-icon [icon]="faTrash" class="icon" size="lg"></fa-icon>
      <div class="flex-grow-1 ml-1 firstCap">sondage expirés</div>
      <div class="number">10</div>
    </div>
  </div>

  <div class="sections">
    <div *appRight="['ADM_ACC']" class="rowSelection" routerLink="admin" routerLinkActive="selected">
      <fa-icon [icon]="faCog" class="icon" size="lg"></fa-icon>
      <div class="">Administration</div>
    </div>
  </div>
</div>
