export class User {
  id: string;
  email: string;
  role: string;
  password: string;
  username: string;
  first_name: string;
  last_name: string;
  description: string;
  modified: Date;
  created: Date;
  photo: string;
}
